<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar>
          <template #start>
           <Button
              @click="go_to"
              style="width: 40%"
              icon="pi pi-fw pi-arrow-circle-left"
              class="p-button-rounded p-button-info"
            />
            &nbsp;
            &nbsp;
            <Dropdown
              style="width: 200%"
              id="status"
              v-model="submissionstatus"
              :options="submissionItems"
              :value="submissionItems.value"
              optionLabel="name"
              placeholder="Select Year"
              :class="{ 'p-invalid': submitted && !selectstatus }"
              required="true"
            ></Dropdown>

            &nbsp;
            <Dropdown
              style="width: 200%"
              id="status"
              v-model="coursestatus"
              :options="courseItems"
              :value="courseItems.value"
              optionLabel="name"
              placeholder="Select Cource"
              :class="{ 'p-invalid': submitted && !selectstatus }"
              required="true"
            ></Dropdown>
            &nbsp;
            <Calendar
              style="width: 200%"
              v-model="from_date"
              :showIcon="true"
              dateFormat="d-MM-yy"
              hourFormat="12"
              :numberOfMonths="2"
            />
            &nbsp;
            <Calendar
              style="width: 200%"
              v-model="to_date"
              :showIcon="true"
              dateFormat="d-MM-yy"
              hourFormat="12"
              :numberOfMonths="2"
            />
            &nbsp;&nbsp;&nbsp;
            <Button
              @click="get_list"
              style="width: 40%"
              icon="pi pi-search"
              class="p-button-rounded p-button-success"
            />
          </template>

          <template #end>
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
           :rowHover="true" showGridlines
        >
        <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Teacher's Assignment and Student Submission</b> </h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>
           <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
          <Column header="Schedule" :field="columns[0]" style="min-width: 10rem">
            <template #body="{ data }">
              <span
                v-if="
                  data.schedule == '' ||
                  data.schedule == null ||
                  data.schedule == undefined
                "
                >-</span
              >
              <span v-else>{{ data.schedule }}</span>
            </template>
          </Column>

          <Column
            header="Teacher Name"
            :field="columns[1]"
            style="min-width: 8rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.teacherName == '' ||
                  data.teacherName == null ||
                  data.teacherName == undefined
                "
                >-</span
              >
              <span v-else>{{ data.teacherName }}</span>
            </template>
          </Column>
          <Column header="Subject" :field="columns[3]" style="min-width: 5rem">
            <template #body="{ data }">
              <span
                v-if="
                  data.subject == '' ||
                  data.subject == null ||
                  data.subject == undefined
                "
                >-</span
              >
              <span v-else>{{ data.subject }}</span>
            </template>
          </Column>
          <Column header="Topic" :field="columns[4]" style="min-width: 5rem">
            <template #body="{ data }">
              <span
                v-if="
                  data.topic == '' ||
                  data.topic == null ||
                  data.topic == undefined
                "
                >-</span
              >
              <span v-else>{{ data.topic }}</span>
            </template>
          </Column>
          <Column header="Cource" :field="columns[2]" style="min-width: 8rem">
            <template #body="{ data }">
              <span
                v-if="
                  data.cource == '' ||
                  data.cource == null ||
                  data.cource == undefined
                "
                >-</span
              >
              <span v-else>{{ data.cource }}</span>
            </template>
          </Column>
          <Column header="Attended" :field="columns[5]" style="min-width: 5rem;text-align: right;">
            <template #body="{ data }">
              <span
                v-if="
                  data.view == '' || data.view == null || data.view == undefined
                "
                >-</span
              >
              <span v-else>{{ data.view }}</span>
            </template>
          </Column>
          <Column
            header="Study Material"
            :field="columns[9]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.studymaterial == '' ||
                  data.studymaterial == null ||
                  data.studymaterial == undefined
                "
                >-</span
              >
              
              <span v-else
                  ><Button
                    label="View"
                    class="p-button-outlined"
                    @click="view_video(data.studymaterial)"
                /></span>

            </template>
          </Column>
          <Column
            header="Assigment To Do"
            
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.assignment == '' ||
                  data.assignment == null ||
                  data.assignment == undefined
                "
                >-</span
              >
              <span v-else
                  ><Button
                    label="View"
                    class="p-button-outlined"
                    @click="view_video(data.assignment)"
                /></span>

       
            </template>
          </Column>
          <Column
            header="Answer(By Teacher)"
            
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.teacherAnswer == '' ||
                  data.teacherAnswer == null ||
                  data.teacherAnswer == undefined
                "
                >-</span
              >
            

               <span v-else
                  ><Button
                    label="View"
                    class="p-button-outlined"
                    @click="view_video(data.teacherAnswer)"
                /></span>
            </template>
          </Column>
          <Column
            header="No Of Submissions(By Student)"
            :field="columns[10]"
            style="min-width: 5rem;text-align: right;"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.studentSubmission == '' ||
                  data.studentSubmission == null ||
                  data.studentSubmission == undefined
                "
                >-</span
              >
              <span v-else>{{ data.studentSubmission }}</span>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Teacher Remark On Student Answer"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="studenT_ID">Student ID</label>
                <InputText
                  id="studenT_ID"
                  v-model.trim="product.studenT_ID"
                  required="true"
                  autofocus
                  disabled
                  :class="{
                    'p-invalid': submitted && !product.studenT_ID,
                  }"
                />
              </div>
              <div class="field">
                <label for="studentName">Student Name</label>
                <InputText
                  id="studentName"
                  v-model.trim="product.studentName"
                  required="true"
                  autofocus
                  disabled
                  :class="{
                    'p-invalid': submitted && !product.studentName,
                  }"
                />
              </div>

              <div class="field">
                <label for="status">Check By Teacher</label>
                <Dropdown
                  id="status"
                  v-model="selectstatus"
                  :options="dropdownItems"
                  :value="dropdownItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
              </div>

              <div class="field">
                <label for="teacheR_REMARK">Teacher Remark</label>
                <Textarea
                  id="teacheR_REMARK"
                  v-model.trim="product.teacheR_REMARK"
                  required="true"
                  autofocus
                  rows="5"
                  :class="{
                    'p-invalid': submitted && !product.teacheR_REMARK,
                  }"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Updatesubmission"
              />
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteVideo"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      
      from_date: new Date(),
      to_date: new Date(),
      uploadproductDialog: false,
      stdDialog: false,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },
      courseItems: [],
      coursestatus: { name: "", value: "" },
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      
      
      dropdownItems: [
        { name: "YES", value: "YES" },
        { name: "NO", value: "NO" },
      ],

      stdproducts: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      page_no: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
    this.get_course_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    go_to()
    {
      this.$router.push('/adminreport');
    },
    view_video(video) {
      // alert(video)
      window.open(video, "_blank");
    },
    
    get_course_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.courseItems = [];
          for (var i = 0; i < info.length; i++) {
            this.courseItems.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },

    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      if (
        this.submissionstatus.value &&
        this.coursestatus.value &&
        this.from_date &&
        this.to_date
      ) {
        var from = new Date(this.from_date);
        var to = new Date(this.to_date);
        var f_date =
          from.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          from.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          from.toLocaleDateString("en-US", { day: "2-digit" });

        var t_date =
          to.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          to.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          to.toLocaleDateString("en-US", { day: "2-digit" });

        var data = {
          page_no: this.page_no,
          count: true,
          from_date: f_date,
          to_date: t_date,
          batch: this.coursestatus.value,
         // teacher_id: parseInt(localStorage.getItem("id")),
          year: this.submissionstatus.value,
          limit: this.limit,
        };
        this.loading = true;
        var promise = apis.adminteacherlecreport(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data;
          //alert(this.totalRecords);
        });
      }
    },
    refresh() {
      this.product = null;
      this.submissionstatus = { name: "", value: "" };
    },
    async get_list() {
      // if(this.submissionstatus.value=='')
      // {
      //    this.$toast.add({severity:'error', summary: 'Error Message', detail:'Select Year', life: 3000});
      //       return false;
      // }
      // if(this.coursestatus.value=='')
      // {
      //    this.$toast.add({severity:'error', summary: 'Error Message', detail:'Select Cource', life: 3000});
      //       return false;
      // }
      // if(this.from_date=='')
      // {
      //    this.$toast.add({severity:'error', summary: 'Error Message', detail:'Select From date', life: 3000});
      //       return false;
      // }
      // if(this.to_date=='')
      // {
      //    this.$toast.add({severity:'error', summary: 'Error Message', detail:'Select To Date', life: 3000});
      //       return false;
      // }
      if (
        this.submissionstatus.value &&
        this.coursestatus.value &&
        this.from_date &&
        this.to_date
      ) {
        var from = new Date(this.from_date);
        var to = new Date(this.to_date);
        var f_date =
          from.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          from.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          from.toLocaleDateString("en-US", { day: "2-digit" });

        var t_date =
          to.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          to.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          to.toLocaleDateString("en-US", { day: "2-digit" });

        var data = {
          page_no: this.page_no,
          count: false,
          from_date: f_date,
          to_date: t_date,
          batch: this.coursestatus.value,
        //  teacher_id: parseInt(localStorage.getItem("id")),
          year: this.submissionstatus.value,
          limit: this.limit,

          
        };
        this.loading = true;
        var promise = apis.adminteacherlecreport(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        await this.get_count();
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Updatesubmission() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please select check by teacher",
          life: 3000,
        });
        return false;
      }
      if (!this.product.teacheR_REMARK) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Remark",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.tscH_DE_ID) {
        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          STUDENT_ID: this.product.studenT_ID,
          TEACHER_REMARK: this.product.teacheR_REMARK,
          TEACHER_CHECK: this.selectstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.updateteacherremark(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
